import React from 'react'
import { mybullet, skills } from '../utils/constants/contants.js'
import useAuth from '../common/useAuth.js'
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

function ResumeComponent({isLoading}) {

    useAuth();

    const navigate = useNavigate();

    const downloadAPK = () => {
        const apkUrl = process.env.PUBLIC_URL + '/APK_folder/app-release.apk';
        const link = document.createElement('a');
        link.href = apkUrl;
        link.download = 'app-release.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <Dimmer active={isLoading}>
                <Loader active={isLoading}/>
            </Dimmer>
            <div >
                <span style={{ width: '110px', height: '1px', borderTop: '1px solid black' }}>

                </span>
                <span className='my-jounery-tag' style={{ fontSize: '16px', fontWeight: 'bold', color: '#fffff', marginLeft: '154px' }}><h2>My Journey </h2></span>
                <div className='boder-of-jurney-tag' style={{ width: '390px', height: '1px', borderTop: '1px solid black', position: 'relative', left: '39vw' }}>

                </div>
            </div>
            <br></br>
            <div>
                <span style={{ margin: 'auto' }}>
                    <p style={{paddingLeft: '10px', paddingRight: '14px', position: "relative", left: "21%", width: '54%', fontSize: '17px'}}>
                        Hey, I'm Shubham Singh Dynamic Full Stack Developer with a passion for crafting innovative and efficient solutions in web development.
                        With a solid foundation in both  front-end and back-end technologies, seamlessly navigates the complexities of modern software development  to create robust and user-friendly applications.
                        Armed with extensive knowledge of  programming languages such as JavaScript, Python, and NodeJS.
                    </p>
                </span>
            </div>

            <div className='whole-resum-container' style={{ display: 'flex' }}>
                <div className='experince-tag'>
                    <h4>Experince</h4>
                    <b>Agile Cockpit Software Pvt Ltd- Technical Developer</b>
                    <span style={{marginLeft:'5px'}}>Gurugram India </span>
                    <i>05/22- current</i>
                    <div className='project-list-container' style={{ marginTop: '26px' }}>
                        <div className='title-heading'>Live Projects</div>
                        <div className='project-title'>
                            <div className='project-link' onClick={() => navigate('/dashboard')}>Kanban Dashboard</div>
                            <div className='project-link' onClick={() => navigate('/match-data')}> Match Data Visulization</div>
                            <div className='project-link' onClick={() => downloadAPK()} > {`Real Estate Project (Expo React-Native)`} </div>
                        </div>
                    </div>

                </div>
                
                <div className='summuray-container' style={{width: '45%'}}>
                    <div style={{ marginLeft: ' 20px', fontSize: '25px', fontWeight: 600, marginTop: '30px', position:'relative', left: '28%' }}>Summary</div>
                    <ul style={{listStyleType: 'none', left: "25%", position: 'relative', }}>
                    {
                        mybullet?.map((item, i) => (
                            <li key={i} style={{fontSize: "20px", marginTop: "10px"}}> {item}</li>
                        ))
                    }
                    </ul>
                </div>
                <div className='skills-wala-container'>
                    <div className='skill-heading'>Skills</div>
                    <div style={{marginTop: "20px"}}>
                        {
                            skills?.map((item,i) => (
                                <span key={i} className='skills-tag'>{`${item} `}</span>
                            ))
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

const mapStateToProp = (state) => {
    return {
        isLoading: state.userReducer.isLoading
    }
}

const mapDispatchToProp = () => {
    return {

    }
}

const Resume = connect(mapStateToProp, mapDispatchToProp)(ResumeComponent);

export default Resume;
