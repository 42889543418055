import React, { useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'

export default function Toater({message, type}) {

    const [hideToaster, setHideToaster] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setHideToaster(true);
        }, 3000);
    }, []);

  return (
    <Message as={'function'} hidden={hideToaster} error style={{width: '20%', position: 'absolute', right:'10px', top: '20px', zIndex: 3}}>
        {message}
    </Message>
  )
}
