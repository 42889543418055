export const GET_PHASES_LIST = 'GET_PHASES_LIST'
export const GET_PHASES_LIST_SUCCESS = 'GET_PHASES_LIST_SUCCESS'
export const GET_PHASES_LIST_FAILURE = 'GET_PHASES_LIST_FAILURE'

export const GET_WORKITEM_LIST = 'GET_WORKITEM_LIST'
export const GET_WORKITEM_LIST_SUCCESS = 'GET_WORKITEM_LIST_SUCCESS'
export const GET_WORKITEM_LIST_FAILURE = 'GET_WORKITEM_LIST_FAILURE'

export const UPDATE_WORKITEM_PHASE = 'UPDATE_WORKITEM_PHASE'
export const UPDATE_WORKITEM_PHASE_SUCCESS = 'UPDATE_WORKITEM_PHASE_SUCCESS'
export const UPDATE_WORKITEM_PHASE_FAILURE = 'UPDATE_WORKITEM_PHASE_FAILURE'

export const CREATE_WORKITEM = 'CREATE_WORKITEM'
export const CREATE_WORKITEM_SUCCESS = 'CREATE_WORKITEM_SUCCESS'
export const CREATE_WORKITEM_FAILURE = 'CREATE_WORKITEM_FAILURE'