import React, { useEffect, useState } from 'react'
import { Button, Dimmer, Label, Loader } from 'semantic-ui-react'
import './css/index.css'
import { Link } from 'react-router-dom'
import Toater from '../../../components/common/Toater'

export default function Login({userLogin, status, accessToken, isLoading, errorMessage}) {
  
  const [formobj, setformobj] = useState({
    email:'',
    password:''
  })

  useEffect(() => {
    switch (status) {
      case 'USER_LOGIN_SUCCESS':
        window.localStorage.setItem('accessToken' ,accessToken.access_token)
        window.location.assign('/')
        break;
      default:
        break;
    }
  }, [status, accessToken])

  const loginHandler = () => {
    userLogin(formobj);
  }

  return (
    <div className='main-container'>
      {
        errorMessage?.length ? <Toater message={errorMessage} /> : ''
      }
      <Dimmer active={isLoading}><Loader active={isLoading}/></Dimmer>
      <div className='field-container'>
      {
        formobj.email.length || formobj.password.length ? "" : <li className='warning-text'>Please enter the details</li>
      }
      <Label color='none'  style={{backgroundColor:'transparent', fontSize: '14px', marginBottom:"10px"}}>Email</Label>
      <input className='input-field' onChange={(e) => {setformobj({...formobj, email:e.target.value})}} type='text' placeholer='enter the email'/>
      <Label className='label field' style={{backgroundColor:'transparent', fontSize:'14px', marginBottom: "10px"}} >Password</Label>
      <input className='input-field' onChange={(e) => {setformobj({...formobj, password:e.target.value})}} type='password' placeholer='enter the password'/>
      <Button className='login-btn' color='blue' onClick={() => loginHandler()} style={{marginTop:'10px', borderRadius: '10px'}}>Sign in</Button>
      <Link to={'/register'} className="link-text">Sign up</Link>
      </div>
    </div>
  )
}
