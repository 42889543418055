export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL"

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const OTP_VERIFY = 'OTP_VERIFY'
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS'
export const OTP_VERIFY_FAILURE = 'OTP_VERIFY_FAILURE'

export const RESEND_OTP = 'OTP_VERIFY'
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS'
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE'

export const GET_USER_DETAIL = 'GET_USER_DETAIL'
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS'
export const GET_USER_DETAIL_FAILURE = 'GET_USER_DETAIL_FAILURE'