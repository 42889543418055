import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dimmer, Input, Loader } from 'semantic-ui-react'

export default function VerifyOtp({status, verifyOtp, resendOtp, email, isLoading}) {
    const navigate = useNavigate();

    const [formObj, setformObj] = useState({
        email: email ? email : '',
        otp:''
    })

    useEffect(() => {
        switch (status) {
            case 'OTP_VERIFY_SUCCESS':
                navigate('/login');
                break;
            default:
                break;
        }

    },[status, navigate]) 

    useEffect(() => {
        setformObj({
            ...formObj,
            email: email ? email : ''
        })
    }, [email, formObj])

    const verifyHandler = () => {
        verifyOtp(formObj)
    }

    const resendOtpHandler = () => {
        resendOtp({email: email});
    }


  return (
    <>
        <Dimmer active={isLoading} >
            <Loader active={isLoading}/> 
        </Dimmer>
    <div className='field-container'>
        <div>
            <Input label='Enail' type='text' value={email} disabled style={{width: '50%'}}/>
        </div>
        <div style={{marginTop: "20px"}}>
            <Input label="OTP" onChange={(e) => setformObj({...formObj, otp:e.target.value})} type='number' />
        </div>
        <div>
            <Button style={{ position: 'relative',left: '27%', top: '45px'}} color='blue' onClick={() => verifyHandler()}>Verify</Button>
        </div>
        <div>
            <span className='resend-btn-text' onClick={() => resendOtpHandler()}>resend</span>
        </div>
    </div>
    </>
  )
}
