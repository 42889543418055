import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch('https://lowbit.online/api/user/check-token', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            accessToken: token
          },
        });

        if (!response.ok) {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking token:', error);
        navigate('/login');
      }
    };

    checkToken();
  }, [navigate]);
};

export default useAuth;
