export const mybullet = [
    "Developed technical solutions to improve system performance and scalability.",
    "Analyzed user requirements and translated them into technical specifications.",
    "Designed, implemented, tested, and maintained software applications.",
    "Collaborated with other developers to ensure code quality and accuracy.",
    "Participated in the development of software architecture plans.",
    "Performed debugging tasks to identify application issues and develop resolutions.",
    "Utilized source control systems such as Git to manage project files.",
    "Optimized existing codebases for improved efficiency and reliability.",
    "Researched emerging technologies to incorporate into existing products.",
    "Documented all development processes according to company standards.",
    "Provided guidance and support to junior team members on technical topics.",
    "Generated detailed reports outlining progress made on projects.",
    "Developed web services using RESTful APIs.",
    "Implemented security measures to protect data from unauthorized access.",
    "Evaluated system performance metrics to identify areas for improvement.",
    "Maintained compliance with industry standards throughout the development process.",
    "Designed database structures that optimize query performance.",
    "Monitored production systems for errors or malfunctions.",
    "Analyzed code and corrected errors to optimize output.",
    "Reviewed project requirements to identify customer expectations and resources needed to meet goals.",
    "Worked closely with systems analysts, engineers, and programmers to understand limitations, develop capabilities, and resolve software problems.",
    "Deployed server-side components across multiple environments.",
    "Analyzed user needs and software requirements to determine design feasibility.",
    "Resolved customer issues by establishing workarounds and solutions to debug and create defect fixes.",
    "Recommended improvements to facilitate team and project workflow.",
    "Leveraged Agile methodologies to move the development lifecycle rapidly through initial prototyping to enterprise-quality testing and final implementation."
  ];
  
  export const skills = [
    "HTML5",
    "CSS",
    "JavaScript",
    "React",
    "React Native",
    "NodeJS",
    "ExpressJs",
    "Python",
    "DDL",
    "DML",
    "SQL",
    "NOSQL",
    "MYSQL",
    "MONGODB",
    "Nginx Web Server",
    "Amazon Web Services",
    "Azure Cloud Services",
    "CI/CD Jenkins",
    "Azure DevOps",
    "Quick Learner",
    "Critical Thinking",
    "Problem Solving",
  ];

  export const cricketMatches = [
    { team1: "India", team2: "Pakistan", Teams: "India vs Pakistan", Date: "2024-03-01", Highlights: "Player X awarded Man of the Match" },
    { team1: "Australia", team2: "England", Teams: "Australia vs England", Date: "2024-03-03", Highlights: "Player Y scored a century" },
    { team1: "South Africa", team2: "New Zealand", Teams: "South Africa vs New Zealand", Date: "2024-03-06", Highlights: "Player Z took a hat-trick" },
    { team1: "West Indies", team2: "Sri Lanka", Teams: "West Indies vs Sri Lanka", Date: "2024-03-09", Highlights: "Player W hit the winning runs" },
    { team1: "Bangladesh", team2: "Afghanistan", Teams: "Bangladesh vs Afghanistan", Date: "2024-03-12", Highlights: "Player P scored 5 sixes" },
    { team1: "Pakistan", team2: "Australia", Teams: "Pakistan vs Australia", Date: "2024-03-15", Highlights: "Player Q took 5 wickets" },
    { team1: "England", team2: "South Africa", Teams: "England vs South Africa", Date: "2024-03-18", Highlights: "Player R hit the fastest fifty" },
    { team1: "New Zealand", team2: "West Indies", Teams: "New Zealand vs West Indies", Date: "2024-03-21", Highlights: "Player S achieved a run-out" },
    { team1: "India", team2: "Sri Lanka", Teams: "India vs Sri Lanka", Date: "2024-03-24", Highlights: "Player T scored a double century" },
    { team1: "Australia", team2: "Bangladesh", Teams: "Australia vs Bangladesh", Date: "2024-03-27", Highlights: "Player U hit the winning six" },
    { team1: "Afghanistan", team2: "Pakistan", Teams: "Afghanistan vs Pakistan", Date: "2024-03-30", Highlights: "Player V took a hat-trick" }
  ];
  

  